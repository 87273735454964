<template>

  <div class="divSearch">
    <a-input class="company" placeholder="工厂库存公司名称" v-model:value="searchKey.companyName" @pressEnter="GoEvent"/>
    <a-input class="mobile" v-inputNumber placeholder="手机号" v-model:value="searchKey.mobile" @pressEnter="GoEvent"/>
    <a-select v-model:value="searchKey.status" @change="GoEvent" style="width: 75px">
      <a-select-option value="0">全部</a-select-option>
      <a-select-option value="1">未批</a-select-option>
      <a-select-option value="2">已批</a-select-option>
      <a-select-option value="3">拒绝</a-select-option>
    </a-select>
    <a-select v-model:value="searchKey.quotedType" @change="GoEvent" style="width: 100px">
      <a-select-option value="0">全部</a-select-option>
      <a-select-option value="1">报价结束</a-select-option>
      <a-select-option value="2">报价中</a-select-option>
    </a-select>
    <a-button @click="allEvent.addBom.open()">上传一个BOM</a-button>
    <a-button @click="GoEvent" type="primary">查询</a-button>
  </div>
  <div style="padding:15px;margin-bottom: 20px;">

    <table class="ahuiTable">
      <tr>
        <th rowspan="2">&nbsp;</th>
        <th rowspan="2">编号</th>
        <th rowspan="2">公司名称</th>
        <th rowspan="2">手机</th>
        <th rowspan="2">上传数量</th>
        <th rowspan="2">状态</th>
        <th rowspan="2">上传员工</th>
        <th rowspan="2">估价</th>
        <th rowspan="2">货币</th>
        <th rowspan="2">创建时间</th>
        <th rowspan="2">调查表</th>
        <th rowspan="2">信息</th>
        <th rowspan="2">下载</th>
        <th rowspan="2">明细</th>
        <th colspan="2" v-if="tfun.check(61)">短信推广</th>
        <th colspan="3" v-if="tfun.check(62)">邮件推广</th>
        <th rowspan="2" v-if="tfun.check(61,62)">推广</th>
      </tr>
      <tr>
        <th v-if="tfun.check(61)">已发</th>
        <th v-if="tfun.check(61)">待发</th>
        <th v-if="tfun.check(62)">已发</th>
        <th v-if="tfun.check(62)">待发</th>
        <th v-if="tfun.check(62)">待审</th>
      </tr>
      <tr v-for="aRow in dataValue.data" :style="selectRowColor(aRow['stockBatchId'])"
          @click="selectRowEvent(aRow['stockBatchId'])">
        <td class="logo">
          <day_logo :day="aRow['createTime']"/>
        </td>
        <td class="min">{{ aRow['stockBatchId'] }}</td>
        <td>{{ aRow['companyName'] }}</td>
        <td class="min">{{ aRow['mobile'] }}</td>
        <td class="min number">{{ aRow['upCount'] }}</td>
        <td class="min">{{ aRow['statusName'] }}</td>
        <td class="min" v-if="aRow['employeeName'] === '-'">
          <a-popconfirm title="当前订单只能认领一次，请再次确认BOM归属？" ok-text="是" cancel-text="否" @confirm="allEvent.renLing(aRow)">
            <a>认领</a>
          </a-popconfirm>
        </td>
        <td class="min" v-if="aRow['employeeName'] !== '-'">{{ aRow['employeeName'] }}</td>
        <td class="price">{{ tools.formatPrice( aRow['allAssessPrice'], 2 ) }}</td>
        <td class="min">{{ aRow['dcbOld']['dcb6'] + ( aRow['dcb'].length > 5 ? aRow['dcb'][5]['checkValue'] : '') }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min"><a @click="allEvent.dcb.goOpen(aRow)">调查表</a></td>
        <td class="min"><a @click="userInfoOpen(aRow)">信息</a></td>
        <td class="min"><a @click="downloadEvent(aRow)">下载</a></td>
        <td class="min"><a @click="detailOpen(aRow)">估价</a></td>


        <td class="min number" v-if="tfun.check(61)">
          <span v-if=" aRow['smsCount'] === undefined || aRow['smsCount'] === 0" class="zero">0</span>
          <span v-if=" aRow['smsCount'] !== undefined && aRow['smsCount'] > 0" class="number">{{
              aRow['smsCount']
            }}</span>
        </td>
        <td class="min number" v-if="tfun.check(61)">
          <span v-if=" aRow['bySendCount'] === undefined || aRow['bySendCount'] === 0" class="zero">0</span>
          <span v-if=" aRow['bySendCount'] !== undefined && aRow['bySendCount'] > 0" class="number">
            {{ aRow['bySendCount'] }}
            <a-popconfirm title="确认要删除所有未发送的短信记录？" ok-text="是" cancel-text="否" @confirm="allHttp.ic7.delPlan(aRow)">
              <label class="goDel" style="cursor: pointer;">取消</label>
            </a-popconfirm>
          </span>
        </td>



        <td class="min number" v-if="tfun.check(62)">
          <span v-if=" aRow['sendSize'] === undefined || aRow['sendSize'] === 0" class="zero">0</span>
          <a v-if=" aRow['sendSize'] !== undefined && aRow['sendSize'] > 0" class="number"
             @click="allEvent.mailHistory.goOpen(aRow,1,'查看已发送记录')">{{ aRow['sendSize'] }}</a>
        </td>

        <td class="min number" v-if="tfun.check(62)">
          <span v-if=" aRow['planSize'] === undefined || aRow['planSize'] === 0" class="zero">0</span>
          <span v-if=" aRow['planSize'] !== undefined && aRow['planSize'] > 0" class="number">
            <a class="aShowByMail" @click="allEvent.mailHistory.goOpen(aRow,0, '查看待发送记录')">{{ aRow['planSize'] }}</a>
            <a-popconfirm title="确认要删除所有未发送的记录？" ok-text="是" cancel-text="否" @confirm="allHttp.ic7mail.delPlan(aRow,0)">
              <a class="goDel">取消</a>
            </a-popconfirm>
          </span>
        </td>
        <td class="min number" v-if="tfun.check(62)">
          <span v-if=" aRow['examineSize'] === undefined || aRow['examineSize'] === 0" class="zero">0</span>
          <span v-if=" aRow['examineSize'] !== undefined && aRow['examineSize'] > 0" class="number">
            <a class="aShowByMail" @click="allEvent.mailHistory.goOpen(aRow,2, '查看待审批记录')">{{ aRow['examineSize'] }}</a>
            <a-popconfirm title="确认要删除待审批的记录？" ok-text="是" cancel-text="否" @confirm="allHttp.ic7mail.delPlan(aRow,2)">
              <a class="goDel">取消</a>
            </a-popconfirm>
          </span>
        </td>

        <td class="min" v-if="tfun.check(61,62)">
          <a @click="allEvent.ic7.goOpen(aRow);" v-if="tfun.check(61)">短信</a>
          <a style="margin-left: 4px;" @click="allEvent.mail.goOpen(aRow);" v-if="tfun.check(62)">邮件</a>
        </td>
      </tr>
      <tr>
        <td colspan="23" class="pages">
          <APages :number_of_page="dataValue.pages.numberOfPage"
                  :this_page="dataValue.pages.page" :all_page="dataValue.pages.allPage"
                  @toPage="setPage"
          />
        </td>
      </tr>
    </table>

  </div>

  <!-- A - 打开详细页（ 开始 ）-->
  <a-drawer
      class="aDrawer"
      :title="detailOpenData.title"
      :width="detailOpenData.width"
      :visible="detailOpenData.visible"
      :body-style="detailOpenData.bodyStyle"
      :footer-style="detailOpenData.footerStyle"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="right"
      @close="detailClose"
  >
    <detailPage :stock_batch_id="detailOpenData.stockBatchId" @detailClose="detailClose" :money_type_name="detailOpenData.moneyTypeName" />
  </a-drawer>
  <!-- A - 打开详细页（ 结束 ）-->


  <!-- C - 打开公司信息页（ 开始 ）-->

  <a-drawer
      class="aDrawer"
      :title="userInfoOpenData.title"
      :width="userInfoOpenData.width"
      :visible="userInfoOpenData.visible"
      :body-style="userInfoOpenData.bodyStyle"
      :footer-style="userInfoOpenData.footerStyle"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="userInfoClose"
  >
    <userInfo :company_id="userInfoOpenData.crmCompanyId"/>
  </a-drawer>

  <!-- C - 打开公司信息页（ 开始 ）-->


  <a-drawer
      :title="dataValue.mailHistory.title"
      width="700"
      :visible="dataValue.mailHistory.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.mailHistory.goMailHistoryClose"
  >

    <mailHistory :factory_stockId="dataValue.mailHistory.props.factoryStockId"
                 :stock_batch_id="dataValue.mailHistory.props.stockBatchId"
                 :user_batch_id="dataValue.mailHistory.props.userBatchId"
                 :is_send="dataValue.mailHistory.props.isSend"
                 @goMailClose="allEvent.mailHistory.goMailHistoryClose"/>
  </a-drawer>

  <a-drawer
      title="工厂库存邮件推广"
      width="950"
      :visible="dataValue.mail.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.mail.goClose"
  >

    <mailBatch :row_obj="dataValue.mail.props.rowObj" :crm_type_id="dataValue.mail.props.crmTypeId"
               @goClose="allEvent.mail.goClose2"/>
  </a-drawer>


  <a-drawer
      title="查看Bom的调查表"
      width="380"
      :visible="dataValue.dcb.visible"

      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.dcb.goClose"
  >

    <dcbPage :row_obj="dataValue.dcb.props.rowObj" @goClose="allEvent.dcb.goClose"/>
  </a-drawer>


  <a-drawer
      :title="'工厂库存BOM通过IC7批量询价报价'"
      width="500"
      :visible="dataValue.toIC7.visible"

      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.ic7.goClose"
  >

    <ic7Batch :row_objs="dataValue.toIC7.props.rowObjs" :index_id_name="dataValue.toIC7.props.indexIdName"
              :crm_type_id="dataValue.toIC7.props.crmTypeId" :company_name="dataValue.toIC7.props.companyName"/>
  </a-drawer>

  <a-drawer
      title="上传一个BOM"
      width="780"
      :visible="dataValue.addBom.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="left"
      @close="allEvent.addBom.goClose" >
    <addBomPage :crm_type_id="dataValue.addBom.crmTypeId" @goClose="allEvent.addBom.goClose2" />
  </a-drawer>


</template>

<script>
import {reactive} from "vue";
import {message} from "ant-design-vue";
import httpRequest from "@/utils/axios";
import ahuiTable from "@/components/table";
import day_logo from "@/components/day_logo";
import detailPage from "@/view/order/stock.one.detail"
import userInfo from "@/view/company/user_info"
import APages from "@/tools/pages"
import tools from "@/tools/small_methods"
import mailBatch from "@/view/order/mail_batch"
import mailHistory from "@/view/order/mail_history";
import dcbPage from "@/view/order/stock.one.dcb"
import tfun from "@/tools/tfun";
import ic7Batch from "@/view/order/ic7_batch"
import addBomPage from "@/view/order/add_bom";

export default {
  name : "stock.one",
  components : { ahuiTable, detailPage, userInfo, day_logo, APages, mailBatch, mailHistory, dcbPage, ic7Batch, addBomPage },
  emits : [ 'setMenuKey' ],
  setup(props, { emit }) {
    emit('setMenuKey', 'dj.gckcbj');


    // C - 查看公司信息
    let userInfoOpenData = reactive({

      "crmCompanyId" : 5,
      "title" : "查看库存现货公司信息",
      "visible" : false,
      "width" : 300,
      "bodyStyle" : {
        "padding" : "20px 5px 20px 20px",
        "background-color" : "#fbfbf7"
      },
      "footerStyle" : {
        "textAlign" : "right"
      }
    })

    // C - 激活窗口
    const userInfoOpen = (row) => {
      userInfoOpenData.crmCompanyId = row['crmCompanyId'];
      userInfoOpenData.visible = true;
    }
    const userInfoClose = () => {
      userInfoOpenData.visible = false;
    }


    // A - 详细页 - 打开
    let detailOpenData = reactive({
      "title" : "" +
          "查看Bom的详细信息",
      "visible" : false,
      "stockBatchId" : 0,
      "moneyTypeName": "",
      "width" : 1300,
      "bodyStyle" : {
        "padding" : "20px 5px 20px 20px",
        "background-color" : "#fbfbf7"
      },
      "footerStyle" : {
        "textAlign" : "right"
      }
    })

    // A - 详细页 - 关闭
    let detailClose = () => {
      detailOpenData.visible = false;
      GoEvent();
    };

    // A - 激活窗口
    function detailOpen(row) {
      detailOpenData.stockBatchId = row['stockBatchId'];
      detailOpenData.moneyTypeName = row['dcbOld']['dcb6'] + ( row['dcb'].length > 5 ? row['dcb'][5]['checkValue'] : '');
      detailOpenData.visible = true;
    }


    // 下载文件
    const downloadEvent = (row) => {

      message.destroy();
      message.loading({ content : '正在下载文件(编号:' + row['stockBatchId'] + ')，请稍后 ...', duration : 0 });

      let dataInput = {
        "stockBatchId" : row['stockBatchId'],
        "showPrice" : 1,
        "isDownload" : 1
      }
      httpRequest.postDownload('/admin/stock/list.batch.detail', dataInput)
          .then(() => {
            message.destroy();
          })
    }


    // B -
    let searchKey = reactive({
      "companyName" : "",
      "mobile" : "",
      "status" : "0",
      "quotedType" : "0"
    })

    let dataValue = reactive({
      addBom : {
        visible : false,
        crmTypeId : 1
      },
      selectRow : 0,
      pages : {
        "page" : 1,
        "numberOfPage" : 30,
        "allPage" : 1,
        "allCount" : 0
      },
      data : [],
      dcb : {
        visible : false,
        props : {
          rowObj : null
        }
      },
      mail : {
        visible : false,
        props : {
          rowObj : null,
          crmTypeId : 0
        }
      },
      mailHistory : {
        title : "",
        visible : false,
        props : {
          factoryStockId : 0,
          stockBatchId : 0,
          userBatchId : 0,
          isSend : 1
        }
      },
      toIC7 : {
        "visible" : false,
        props : {
          rowObj : {},
          indexIdName : "",
          crmTypeId : 0,
          companyName : ""
        }
      }
    })


    function setPage(pageId) {
      dataValue.pages.page = pageId;
      GoEvent();
    }

    function GoEvent() {

      let dataInput = {
        "page" : dataValue.pages.page,
        "numberOfPage" : dataValue.pages.numberOfPage,
        "companyName" : searchKey.companyName,
        "mobile" : searchKey.mobile,
        "stockBatchId" : 0,
        "status" : parseInt(searchKey.status),
        "quotedType" : parseInt(searchKey.quotedType)
      }
      httpRequest.post('/admin/stock/list.batch', dataInput)
          .then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.pages.page = dataInput.page;
              dataValue.pages.numberOfPage = dataInput.numberOfPage;
              dataValue.pages.allPage = res.data.allPage;
              dataValue.pages.allCount = res.data.allCount;
              dataValue.data = res.data.list;

              // 获得已发信息和未发信息的记录值
              dataValue.data.forEach((row) => {
                allHttp.ic7mail.getCount(row);
              });
            }
          })
    }

    GoEvent();

    // 选择其中一行
    const selectRowEvent = (rowId) => {
      dataValue.selectRow = rowId;
    }
    let selectRowColor = (rowId) => {
      return dataValue.selectRow === rowId ? "background-color:#d8ffd8;" : "";
    }


    let allHttp = {

      ic7 : {

        RowCount : (row) => {
          httpRequest.post('/admin/ic7/get.send.sms', {
            "factoryStockId" : 0,
            "stockBatchId" : row['stockBatchId'],
            "userBatchId" : 0,
            "onlyCount" : 1,
            "crmTypes" : [ "2", "3" ]
          }).then((res) => {
            if (res.code === 200) {
              row['smsCount'] = res.data.allCount;
            }
          })
        },

        BySendCount : (row) => {
          httpRequest.post('/admin/ic7/plan.count', {
            "factoryStockId" : 0,
            "stockBatchId" : row['stockBatchId'],
            "userBatchId" : 0
          }).then((res) => {
            if (res.code === 200) {
              row['bySendCount'] = res.data.allCount;
            }
          })
        },

        delPlan : (row) => {
          httpRequest.post('/admin/ic7/del.plan', {
            "factoryStockId" : 0,
            "stockBatchId" : row['stockBatchId'],
            "userBatchId" : 0
          }).then((res) => {
            if (res.code === 200) {
              allHttp.ic7.BySendCount(row);
            }
          })
        }
      },

      ic7mail : {

        getCount : (rowObj) => {

          let inputOne = {
            factoryStockId : 0,
            stockBatchId : rowObj['stockBatchId'],
            userBatchId : 0
          }

          httpRequest.post('/admin/ic7.mail/send.count', inputOne)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                } else {
                  rowObj['sendSize'] = res.data.sendSize;
                  rowObj['planSize'] = res.data.planSize;
                  rowObj['examineSize'] = res.data.examineSize;
                }
              })
        },

        delPlan : (rowObj, type) => {
          let inputOne = {
            factoryStockId : 0,
            stockBatchId : rowObj['stockBatchId'],
            userBatchId : 0,
            typeId : type
          }

          httpRequest.post('/admin/ic7.mail/del.plan', inputOne)
              .then((res) => {
                if (res.code !== 200) {
                  message.error(res.message);
                } else {
                  allHttp.ic7mail.getCount(rowObj);
                }
              })
        }

      }

    }


    let allEvent = {

      renLing : (row)=>{

        httpRequest.post('/admin/stock/set.employee', { "stockBatchId":row.stockBatchId })
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                row['employeeName'] = res.data['employeeName'];
              }
            })

      },

      addBom : {

        open : ()=>{
          dataValue.addBom.visible = true;
        },

        goClose : ()=>{
          dataValue.addBom.visible = false;
        },

        goClose2 : ()=>{
          GoEvent();
          dataValue.addBom.visible = false;
        }

      },

      dcb : {

        goOpen : (row) => {
          dataValue.dcb.props.rowObj = row;
          dataValue.dcb.visible = true;
        },

        goClose : () => {
          dataValue.dcb.visible = false;
        }

      },

      ic7 : {

        goOpen : (row) => {

          // console.log(row)

          dataValue.toIC7.props.rowObjs = [];
          dataValue.toIC7.props.rowObjs.push(row);
          dataValue.toIC7.props.indexIdName = "stockBatchId";
          dataValue.toIC7.props.crmTypeId = 1;
          dataValue.toIC7.props.companyName = row['companyName'];
          dataValue.toIC7.visible = true;
        },

        goClose : () => {

          dataValue.toIC7.props.rowObjs.forEach((row) => {
            allHttp.ic7.RowCount(row);
            allHttp.ic7.BySendCount(row);
          });
          dataValue.toIC7.visible = false;
        }

      },

      mail : {

        goOpen : (row) => {
          dataValue.mail.props.rowObj = row;
          dataValue.mail.props.crmTypeId = 1;
          dataValue.mail.visible = true;
          // console.log(dataValue.mail)
        },

        goClose : () => {
          dataValue.mail.visible = false;
        },

        goClose2 : () => {
          allHttp.ic7mail.getCount(dataValue.mail.props.rowObj);
          dataValue.mail.visible = false;
        }

      },

      mailHistory : {
        goOpen : (row, isSend, title) => {
          dataValue.mailHistory.props.stockBatchId = row['stockBatchId'];
          dataValue.mailHistory.props.isSend = isSend;
          dataValue.mailHistory.title = title;
          dataValue.mailHistory.visible = true;
        },

        goMailHistoryClose : () => {
          dataValue.mailHistory.visible = false;
        }

      }

    }


    return {
      dataValue,
      searchKey,
      detailOpenData,
      userInfoOpenData,
      userInfoOpen,
      userInfoClose,
      GoEvent,
      detailOpen,
      setPage,
      detailClose,
      downloadEvent,
      selectRowEvent,
      selectRowColor,
      tools,
      tfun,
      allEvent,
      allHttp
    }

  }
}
</script>

<style scoped>
.divSearch { padding: 15px 15px 0 15px; }
.divSearch * { margin-right: 20px; }
.divSearch .company { width: 350px; }
.divSearch .mobile { width: 150px; }

.aShowByMail { margin: 0 3px 0 3px; }
.aShowByMail:hover { color: red; }
.full { cursor: pointer; margin: 0; }
.full:hover { color: red; }
.mail2 { float: left; margin-right: 4px; cursor: pointer; }
.mail2:hover { color: red; }
</style>
