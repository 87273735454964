<template>

  <view class="t" v-if="dataValue.dcb.length > 0">
    <template v-for="aDCB in dataValue.dcb">
      <view class="title">{{ aDCB['index'] }}、{{ aDCB['question'] }}</view>
      <view class="vInfo">{{ aDCB['checkValue'] }}</view>
    </template>
  </view>

  <view class="t" v-if="dataValue.dcbOld['dcb1'].length > 0">
    <view class="title">1.闲置处理方式</view>
    <view class="vInfo">
      <view v-for="item in dataValue.dcbOld['dcb1']">{{ item }}</view>
    </view>
    <view class="title">2.处理的速度</view>
    <view class="vInfo">{{ dataValue.dcbOld['dcb2'] }}</view>
    <view class="title">3.每年闲置处理情况</view>
    <view class="vInfo">{{ dataValue.dcbOld['dcb3'] }}</view>
    <view class="title">4.闲置物料需要处理的原因</view>
    <view class="vInfo">
      <view v-for="item in dataValue.dcbOld['dcb4']">{{ item }}</view>
    </view>
    <view class="title">5.闲置处理价格预期</view>
    <view class="vInfo">{{ dataValue.dcbOld['dcb5'] }}</view>
    <view class="title">6.计划交易币种</view>
    <view class="vInfo">{{ dataValue.dcbOld['dcb6'] }}</view>
    <view class="title">7.库存所在地</view>
    <view class="vInfo">
      <view v-for="item in dataValue.dcbOld['dcb7']">{{ item }}</view>
    </view>
    <view class="title">8.采购渠道组成</view>
    <view class="vInfo">
      <view v-for="item in dataValue.dcbOld['dcb8']">{{ item }}</view>
    </view>
    <view class="title">9.上传DC信息</view>
    <view class="vInfo">{{ dataValue.dcbOld['dcb9'] }}</view>
    <view class="title">10.付款后，交货交期确认</view>
    <view class="vInfo">{{ dataValue.dcbOld['dcb10'] }}</view>
    <view class="title">11.是否有物料可以长期销售</view>
    <view class="vInfo">{{ dataValue.dcbOld['dcb11'] }}</view>
  </view>
</template>

<script>
import {reactive} from "vue";

export default {
  name : "stock.one.dcb",
  props : [ 'row_obj' ],
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      all : props.row_obj,
      dcbOld : props.row_obj['dcbOld'],
      dcb : props.row_obj['dcb']
    })

    return {
      dataValue
    }
  }
}
</script>

<style scoped>
.t{ padding-bottom: 30px; float: left;}
.title { clear: both; float: left; margin-bottom: 10px; font-weight: bold; }
.vInfo { clear: both; float: left; margin-left: 30px; margin-bottom: 15px; color: #444444; }
.vInfo > view { clear: both; float: left; margin-right: 30px; }
</style>